import { DirectUpload } from '@rails/activestorage';

window.addSelectedListElement = (itemName, itemId, selectionType, resourceType) => {
  const select = document.querySelector(`select[name*="${resourceType}[${selectionType}]"]`);
  const hiddenInput = document.querySelector(
    `input[type="hidden"][id*="${resourceType}_${selectionType}"]`,
  );
  const hiddenInputValue = hiddenInput.value ? hiddenInput.value : '';
  if (hiddenInputValue) {
    const hiddenInputArray = hiddenInputValue.split(/[,\s]/);
    if (hiddenInputArray.includes(itemId)) {
      // eslint-disable-next-line no-alert
      alert(`You have already selected ${itemName}`);
      select.selectedIndex = 0;
      return;
    }
    hiddenInputArray.push(itemId);
    hiddenInput.value = hiddenInputArray;
  } else {
    const hiddenInputArray = [];
    hiddenInputArray.push(itemId);
    hiddenInput.value = hiddenInputArray;
  }
  const iconSpanElement = document.createElement('span');
  iconSpanElement.className = 'bi bi-x';
  iconSpanElement.style = 'float:right';
  iconSpanElement.setAttribute('onclick',
    `removeSelectedListElement(this.parentNode, ${itemId}, '${selectionType}', '${resourceType}')`);
  const listElement = document.createElement('li');
  listElement.className = 'list-group-item custom-list-item';
  listElement.textContent = itemName;
  listElement.appendChild(iconSpanElement);
  const listGroup = document.querySelector(`ul[id="${resourceType}-${selectionType}-selection-list"]`);
  if (hiddenInput.value.split(/[,\s]/).length === 1) {
    listGroup.innerHTML = '';
  }
  listGroup.appendChild(listElement);

  select.selectedIndex = 0;

  // if (listElement) select.setAttribute('disabled', true);
};
window.removeSelectedListElement = (element, itemId, selectionType, resourceType) => {
  // eslint-disable-next-line no-unused-vars
  const select = document.querySelector(`select[name*="${resourceType}[${selectionType}]"]`);
  const hiddenInput = document.querySelector(
    `input[type="hidden"][id*="${resourceType}_${selectionType}"]`,
  );
  const hiddenInputArray = hiddenInput.value.split(/[,\s]/);
  const itemIndex = hiddenInputArray.indexOf(itemId.toString());
  if (itemIndex !== -1) {
    hiddenInputArray.splice(itemIndex, 1);
    hiddenInput.value = hiddenInputArray;
  }
  element.remove();
  if (hiddenInputArray.length === 0) {
    const selectedListGroup = document.querySelector(`ul[id*="${resourceType}-${selectionType}-selection-list"]`);
    const listElement = document.createElement('li');
    listElement.className = 'list-group-item custom-list-item';
    listElement.textContent = `No ${selectionType} selected`;
    selectedListGroup.appendChild(listElement);
  }
};

window.switchModeAndResetPrevious = (listName) => {
  const hiddenInputList = document.querySelector(`input[type="hidden"][id*="${listName}_list"]`);
  hiddenInputList.removeAttribute('value');
  const selectedListGroup = document.querySelector(`ul[id="${listName}-selection-list"]`);
  selectedListGroup.innerHTML = '';
  const selectAllCheckbox = document.querySelector(`input.${listName}-checkbox`);
  selectAllCheckbox.checked = false;
  const selectListBox = document
    .querySelector(`div[class*='other_fields_${listName}'] button[data-id='custom-select']`);
  selectListBox.disabled = false;
};
window.handleCheckboxClick = (isCheckboxChecked, elementId, selectionType, resourceType) => {
  const selectListBox = document.querySelector(`select[name*="${resourceType}[${selectionType}]"]`);
  const hiddenInputList = document.querySelector(`
  input[type="hidden"][id*="${resourceType}_${selectionType}"]`);
  const selectedListGroup = document.querySelector(`ul[id*="${resourceType}-${selectionType}-selection-list"]`);
  if (isCheckboxChecked.checked) {
    selectListBox.disabled = true;
    selectListBox.selectedIndex = 0;
    hiddenInputList.value = elementId;
    selectedListGroup.innerHTML = '';
    const listElement = document.createElement('li');
    listElement.className = 'list-group-item custom-list-item';
    listElement.textContent = `All ${selectionType} selected`;
    selectedListGroup.appendChild(listElement);
  } else {
    selectListBox.disabled = false;
    hiddenInputList.removeAttribute('value');
    selectedListGroup.innerHTML = '';
    const listElement = document.createElement('li');
    listElement.className = 'list-group-item custom-list-item';
    listElement.textContent = `No ${selectionType} selected`;
    selectedListGroup.appendChild(listElement);
  }
};
window.addFilesList = (selectedFiles, inputType, resourceType) => {
  const { files } = selectedFiles;
  const listGroup = document.querySelector(`ul[id="${resourceType}-selected-${inputType}-list"]`);
  listGroup.innerHTML = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < files.length; i++) {
    const input = document.querySelector(`input[type=file][id*=${resourceType}][id*="_${inputType}"]`);
    const url = input.dataset.directUploadUrl;
    const upload = new DirectUpload(files[i], url);

    upload.create((error, blob) => {
      // eslint-disable-next-line no-console
      console.log(blob.signed_id);
    });

    const iconSpanElement = document.createElement('span');
    iconSpanElement.className = 'bi bi-x';
    iconSpanElement.style = 'float:right';
    iconSpanElement.setAttribute('onclick', `removeFile(${i}, '${inputType}', '${resourceType}')`);
    const listElement = document.createElement('li');
    listElement.className = 'list-group-item border-0';
    listElement.id = `${resourceType}-selected-${inputType}-list-element`;
    listElement.textContent = files[i].name;
    listElement.appendChild(iconSpanElement);
    listGroup.style = 'border:2px dashed grey;';
    listGroup.appendChild(listElement);
  }
};

window.removeFile = (index, inputType, resourceType) => {
  const allListElements = document.querySelectorAll(`li#${resourceType}-selected-${inputType}-list-element`);
  // eslint-disable-next-line no-plusplus
  for (let i = index + 1; i < allListElements.length; i++) {
    allListElements[i].children[0].setAttribute('onclick', `removeFile(${i - 1}, '${inputType}')`);
  }
  if (allListElements.length === 1) {
    const listGroup = document.querySelector(`ul[id="${resourceType}-selected-${inputType}-list"]`);
    listGroup.style = '';
  }
  allListElements[index].remove();

  const dt = new DataTransfer();
  // eslint-disable-next-line max-len
  const input = document.querySelector(`input#other_fields_${resourceType}_${inputType}, input[id*="${resourceType}"][id*="element"][id*="_${inputType}"]`);
  const { files } = input;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (index !== i) {
      dt.items.add(file);
    }
  }
  input.files = dt.files;
};
