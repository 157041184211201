import { DirectUpload } from '@rails/activestorage';

const adjustSubmitButtonAvailability = () => {
  const submitButton = document.querySelector('form[id*="email"] input[type="submit"]');
  const hiddenInputs = document.querySelectorAll('form[id*="email"] input[type="hidden"][id*="other_fields"]');
  let chosenEmails = 0;
  hiddenInputs.forEach((input) => {
    chosenEmails += input.value.length;
  });
  if (chosenEmails === 0) {
    submitButton.disabled = true;
  } else {
    submitButton.disabled = false;
  }
};

window.addSelectedListElementMail = (itemName, itemId, selectionType) => {
  const hiddenInput = document.querySelector(`input[type="hidden"][id*="${selectionType}_list"]`);
  const hiddenInputValue = hiddenInput.value ? hiddenInput.value : '';
  if (hiddenInputValue) {
    const hiddenInputArray = hiddenInputValue.split(',');
    if (hiddenInputArray.includes(itemId)) {
      // eslint-disable-next-line no-alert
      alert(`You have already selected ${itemName}`);
      return;
    }
    hiddenInputArray.push(itemId);
    hiddenInput.value = hiddenInputArray;
  } else {
    const hiddenInputArray = [];
    hiddenInputArray.push(itemId);
    hiddenInput.value = hiddenInputArray;
  }
  const iconSpanElement = document.createElement('span');
  iconSpanElement.className = 'bi bi-x';
  iconSpanElement.style = 'float:right';
  iconSpanElement.setAttribute(
    'onclick',
    `removeSelectedListElementMail(this.parentNode, ${itemId}, '${selectionType}')`,
  );
  const listElement = document.createElement('li');
  listElement.className = 'list-group-item custom-list-item';
  listElement.textContent = itemName;
  listElement.appendChild(iconSpanElement);
  const listGroup = document.querySelector(`ul[id="${selectionType}-selection-list"]`);
  listGroup.appendChild(listElement);
  adjustSubmitButtonAvailability();
};

window.removeSelectedListElementMail = (element, itemId, selectionType) => {
  const hiddenInput = document.querySelector(`input[type="hidden"][id*="${selectionType}_list"]`);
  const hiddenInputArray = hiddenInput.value.split(',');
  const itemIndex = hiddenInputArray.indexOf(itemId.toString());
  if (itemIndex !== -1) {
    hiddenInputArray.splice(itemIndex, 1);
    hiddenInput.value = hiddenInputArray;
  }
  element.remove();
  adjustSubmitButtonAvailability();
};

window.switchModeAndResetPreviousMail = (listName) => {
  const hiddenInputList = document.querySelector(`input[type="hidden"][id*="${listName}_list"]`);
  hiddenInputList.removeAttribute('value');
  const selectedListGroup = document.querySelector(`ul[id="${listName}-selection-list"]`);
  selectedListGroup.innerHTML = '';
  const selectAllCheckbox = document.querySelector(`input.${listName}-checkbox`);
  selectAllCheckbox.checked = false;
  const selectListBox = document
    .querySelector(`div[class*='other_fields_${listName}'] select[id*='custom-select' ]`);
  selectListBox.disabled = false;
  adjustSubmitButtonAvailability();
};

window.handleCheckboxClickMail = (isCheckboxChecked, usersId, selectionType) => {
  const selectListBox = document
    .querySelector(`div[class*='other_fields_${selectionType}'] select[id*='custom-select' ]`);
  const hiddenInputList = document.querySelector(`input[type="hidden"][id*="${selectionType}_list"]`);
  const selectedListGroup = document.querySelector(`ul[id="${selectionType}-selection-list"]`);
  if (isCheckboxChecked.checked) {
    selectListBox.disabled = true;
    hiddenInputList.value = usersId;
    selectedListGroup.innerHTML = '';
    const listElement = document.createElement('li');
    listElement.className = 'list-group-item custom-list-item';
    listElement.textContent = `All ${selectionType} selected`;
    selectedListGroup.appendChild(listElement);
  } else {
    selectListBox.disabled = false;
    hiddenInputList.removeAttribute('value');
    selectedListGroup.innerHTML = '';
  }
  adjustSubmitButtonAvailability();
};

window.addFilesList = (selectedFiles, inputType) => {
  const { files } = selectedFiles;
  const listGroup = document.querySelector(`ul[id="selected-${inputType}-list"]`);
  listGroup.innerHTML = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < files.length; i++) {
    const input = document.querySelector(`input[type=file][id*=${inputType}]`);
    const url = input.dataset.directUploadUrl;
    const upload = new DirectUpload(files[i], url);

    upload.create((error, blob) => {
      console.log(blob.signed_id);
    });

    const iconSpanElement = document.createElement('span');
    iconSpanElement.className = 'bi bi-x';
    iconSpanElement.style = 'float:right';
    iconSpanElement.setAttribute('onclick', `removeFile(${i}, '${inputType}')`);
    const listElement = document.createElement('li');
    listElement.className = 'list-group-item border-0';
    listElement.id = `selected-${inputType}-list-element`;
    listElement.textContent = files[i].name;
    listElement.appendChild(iconSpanElement);
    listGroup.style = 'border:2px dashed grey;';
    listGroup.appendChild(listElement);
  }
};

window.removeFile = (index, inputType) => {
  const allListElements = document.querySelectorAll(`li#selected-${inputType}-list-element`);
  // eslint-disable-next-line no-plusplus
  for (let i = index + 1; i < allListElements.length; i++) {
    allListElements[i].children[0].setAttribute('onclick', `removeFile(${i - 1}, '${inputType}')`);
  }
  if (allListElements.length === 1) {
    const listGroup = document.querySelector(`ul[id="selected-${inputType}-list"]`);
    listGroup.style = '';
  }
  allListElements[index].remove();

  const dt = new DataTransfer();
  const input = document.querySelector(`input#other_fields_${inputType}`);
  const { files } = input;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (index !== i) {
      dt.items.add(file);
    }
  }
  input.files = dt.files;
};
