document.addEventListener('turbolinks:load', () => {
  const forms = document.querySelectorAll('.form.customized');
  if (forms.length !== 0) {
    forms.forEach((form, index) => {
      const currentId = form.getAttribute('id');
      form.setAttribute('id', `${currentId}_${index}`);
      const inputs = form.querySelectorAll('input.form-control, select.form-control, input.form-check-input');
      if (inputs.length !== 0) {
        inputs.forEach((input) => {
          const currentInputId = input.getAttribute('id');
          input.setAttribute('id', `${currentInputId}_${index}`);
        });
      }
    });
  }
});
