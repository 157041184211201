// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'chartkick';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('bootstrap');
require('../src/regions_industries_picker');
require('../src/direct_upload');
require('../src/add_element_report');
require('../src/add_element_insight');
require('../src/companies');
require('../src/crm');
require('../src/toggle_text');
require('../src/add_unique_ids');
require('../src/mail_generator');
require('../src/add_option');
require('../src/convert_excel_to_json');
require('../src/flash_messages');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
