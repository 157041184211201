window.changeMainRegion = (regionId, userId) => {
  $.ajax({
    type: 'PATCH',
    url: `/admin/users/${userId}`,
    data: { user: {region_id: regionId} },
  });
};

window.changeRole = (role, userId) => {
  $.ajax({
    type: 'PATCH',
    url: `/admin/users/${userId}`,
    data: { user: {role: role} },
  });
};

window.changeInvestorType = (investor_type, userId) => {
  $.ajax({
    type: 'PATCH',
    url: `/admin/users/${userId}`,
    data: { user: {investor_type: investor_type} },
  });
};