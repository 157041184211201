window.toggleVisibility = (selector) => {
  const element = document.querySelector(selector);
  const submitButton = element.querySelector('input[type="submit"]');
  const selectedElementType = element.querySelector('*[id*="element_element_type"').selectedOptions[0].value;

  switch (selectedElementType) {
    case 'image_element':
      submitButton.disabled = false;
      break;
    case 'chart_element':
      submitButton.disabled = false;
      break;
    case 'table_element':
      submitButton.disabled = false;
      break;
    case 'text_paragraph_element':
      submitButton.disabled = false;
      break;
    default:
      submitButton.disabled = true;
  }
};

window.renumerateElements = () => {
  const elements = document.querySelectorAll('div[class*="element-fields"]');
  for (let i = 0; i < elements.length; i += 1) {
    elements[i].querySelector('input[id*="order"]').setAttribute('value', i);
    elements[i].outerHTML = elements[i].outerHTML.replaceAll(/report_element\d*/g, `report_element${i}`);
  }
};

const renumerateElements = () => {
  const elements = document.querySelectorAll('div[class*="element-fields"]');
  for (let i = 0; i < elements.length; i += 1) {
    elements[i].querySelector('input[id*="order"]').setAttribute('value', i);
    elements[i].outerHTML = elements[i].outerHTML.replaceAll(/report_element\d*/g, `report_element${i}`);
  }
};

window.moveElementUp = (buttonElement) => {
  const element = buttonElement.closest('div[class*="element-fields"]');
  const buffer = element.innerHTML;
  const sibling = document.getElementById(`report_element${parseInt(element.id.match(/\d+/)[0], 10) - 1}`);
  if (sibling != null) {
    element.innerHTML = sibling.innerHTML;
    sibling.innerHTML = buffer;
  }
  renumerateElements();
};

window.moveElementDown = (buttonElement) => {
  const element = buttonElement.closest('div[class*="element-fields"]');
  const buffer = element.innerHTML;
  const sibling = document.getElementById(`report_element${parseInt(element.id.match(/\d+/)[0], 10) + 1}`);
  if (sibling != null) {
    element.innerHTML = sibling.innerHTML;
    sibling.innerHTML = buffer;
  }
  renumerateElements();
};

window.destroyElement = (buttonElement) => {
  buttonElement.closest('div[class*="element-fields"]').remove();
  const elements = document.querySelectorAll('div[class*="element-fields"]');
  const anchors = document.querySelectorAll('#element-anchor');
  if (elements.length === 0) {
    for (let i = 0; i < anchors.length; i += 1) {
      anchors[i].style.display = 'none';
    }
  }
  renumerateElements();
};

window.destroyAllElements = () => {
  const elements = document.querySelectorAll('div[class*="element-fields"]');
  for (let i = 0; i < elements.length; i += 1) {
    elements[i].remove();
  }
  const anchors = document.querySelectorAll('#element-anchor');
  for (let i = 0; i < anchors.length; i += 1) {
    anchors[i].style.display = 'none';
  }
};

window.changeResourceButtonsAbility = (value) => {
  const resourceButtons = document.getElementById('resource').querySelectorAll('button');
  for (let i = 0; i < resourceButtons.length; i += 1) {
    resourceButtons[i].disabled = value;
  }
};

window.changeFormInputsAbilityReport = (value) => {
  const formInputs = document.querySelector('form[id*="edit_report"]');
  if (formInputs !== null) {
    formInputs.querySelectorAll('input, select, textarea');
    for (let i = 0; i < formInputs.length; i += 1) {
      formInputs[i].disabled = value;
    }
  }
};

window.changeElementButtonsAbility = (value) => {
  const elementButtons = document.querySelectorAll('#element-buttons-container input');
  for (let i = 0; i < elementButtons.length; i += 1) {
    elementButtons[i].disabled = value;
  }
};

window.changeElementsAbility = (value) => {
  const elementContainers = document.querySelectorAll('.element_container');
  if (value) {
    for (let i = 0; i < elementContainers.length; i += 1) {
      elementContainers[i].classList.remove('disabled');
      const additionalContents = elementContainers[i].querySelectorAll('.additional_content');
      for (let j = 0; j < additionalContents.length; j += 1) {
        additionalContents[j].style = 'display: block';
      }
    }
  } else {
    for (let i = 0; i < elementContainers.length; i += 1) {
      elementContainers[i].classList.add('disabled');
      const additionalContents = elementContainers[i].querySelectorAll('.additional_content');
      const textContents = elementContainers[i].querySelectorAll('.text_content');
      for (let j = 0; j < additionalContents.length; j += 1) {
        additionalContents[j].style = 'display: none';
      }
      for (let j = 0; j < textContents.length; j += 1) {
        const text = textContents[j].innerText.length > 40
          ? `${textContents[j].innerText.substr(0, 40)}...`
          : textContents[j].innerText;
        textContents[j].innerText = text;
      }
    }
  }
};

window.removeListElements = () => {
  const listElementRemovers = document.querySelectorAll('*[onclick*="removeSelectedListElement"]');
  for (let i = 0; i < listElementRemovers.length; i += 1) {
    listElementRemovers[i].remove();
  }
};
