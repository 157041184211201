window.toggleText = (e) => {
  const dots = e.previousElementSibling.previousElementSibling;
  const moreText = e.previousElementSibling;
  const btnText = e;

  if (dots.style.display === 'none') {
    dots.style.display = 'inline';
    btnText.innerHTML = 'Read more';
    moreText.style.display = 'none';
  } else {
    dots.style.display = 'none';
    e.innerHTML = 'Read less';
    moreText.style.display = 'inline';
  }
};
